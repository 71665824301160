<template>
  <material-card color="third">
    <template v-slot:heading>
      <v-tabs v-model="tab" background-color="transparent" slider-color1="white" show-arrows center-active>
        <v-tab class="mr-3"> <v-checkbox class="legend-chk shrink mr-2 mt-0" v-model="showLegend" dense hide-details></v-checkbox>Графики </v-tab>
        <v-tab v-if="getAccess('object.showPlanning')" class="mr-3">
          <div class="px-0 py-0" style="justify-content: space-between">Товары</div>
        </v-tab>
        <v-tab v-if="getAccess('object.showPlanning')" class="mr-3">
          <div class="px-0 py-0" style="justify-content: space-between">Услуги</div>
        </v-tab>
      </v-tabs>
    </template>
    <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
      <v-tab-item>
        <v-row class="px-1">
          <v-col cols="12" sm="6" class="pa-1">
            <v-card class="ma-1 pa-1">
              <s-doughnut ref="graf1" :showLegend="showLegend" :chart="chart.goods" :title="'Расходы на товары'" />
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" class="pa-1">
            <v-card class="ma-1 pa-1">
              <s-doughnut ref="graf_service" :chart="chart.service" :showLegend="showLegend" :title="'Расходы на услуги'" />
            </v-card>
          </v-col>
          <v-col cols="12" sm="6" class="pa-1">
            <v-card class="ma-1 pa-1">
              <s-doughnut ref="grafBuying" :chart="chart.buying" :showLegend="showLegend" :title="' Покупки'" />
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item v-if="getAccess('object.showPlanning')">
        <el-table
          v-if="expensesCalc?.['l_0']?.['goods']"
          :data="[expensesCalc?.['l_0']?.['goods']]"
          style="width: 100%; min-height: 200px"
          row-key="id"
          border
          lazy
          highlight-current-row
          :load="loadChildren"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          :max-height="$root.windowSize.innerHeight * 0.75"
          size="small"
          @cell-click="tableSellClick"
        >
          <el-table-column prop="name" label="Наименование" sortable width="300"> </el-table-column>
          <el-table-column prop="qty" label="Кол-во" sortable width="85"> </el-table-column>
          <el-table-column :formatter="formatNumber" prop="total_plan" label="Всего (план)" sortable width="130"> </el-table-column>
          <el-table-column :formatter="formatNumber" prop="price_plan" label="Закуплено (план)" sortable width="150"> </el-table-column>
          <el-table-column :formatter="formatNumber" prop="price_real" label="Закуплено (факт)" sortable width="150"> </el-table-column>
          <el-table-column :formatter="formatNumber" prop="payment" label="Оплачено" sortable width="150"> </el-table-column>
          <el-table-column :formatter="formatNumber" prop="percent" label="% оплаты" sortable width="150"> </el-table-column>
          <el-table-column prop="name_doc" label="Документ" sortable width="200"> </el-table-column>
          <el-table-column prop="createdon" label="Дата создания" sortable width="200"> </el-table-column>
        </el-table>
      </v-tab-item>
      <v-tab-item v-if="getAccess('object.showPlanning')">
        <el-table
          v-if="expensesCalc?.['l_0']?.['services']"
          :data="[expensesCalc?.['l_0']?.['services']]"
          style="width: 100%; min-height: 200px"
          row-key="id"
          border
          lazy
          highlight-current-row
          :load="loadChildren"
          :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          :max-height="$root.windowSize.innerHeight * 0.8"
          size="small"
          @cell-click="tableSellClick"
        >
          <el-table-column prop="name" label="Наименование" sortable width="300"> </el-table-column>
          <el-table-column :formatter="formatNumber" prop="price_real" label="Стоимость (факт)" sortable width="150"> </el-table-column>
          <el-table-column :formatter="formatNumber" prop="payment" label="Оплачено" sortable width="150"> </el-table-column>
          <el-table-column :formatter="formatNumber" prop="percent" label="% оплаты" sortable width="150"> </el-table-column>
          <el-table-column prop="description" label="Примечание" sortable width="200"> </el-table-column>
          <el-table-column prop="name_doc" label="Документ" sortable width="200"> </el-table-column>
          <el-table-column prop="createdon" label="Дата создания" sortable width="200"> </el-table-column>
        </el-table>
      </v-tab-item>
    </v-tabs-items>
  </material-card>
</template>

<script>
import { getAccess, autoHeightBlock, popupMenu } from "@/components/mixings";
export default {
  mixins: [getAccess, autoHeightBlock, popupMenu],
  components: {},
  props: {
    id: Number,
    expensesCalc: { type: Object, default: () => {} },
    data: Object,
    chart: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      idEdit: 0,
      oldDoc: false,
      loading: true,
      showEditDialog: false,
      showLegend: true,
      title: "",
      tab: 0,
      clientHeight: 0,
    };
  },
  created() {
    this.loading = true;
    this.permit = this.getAccess("menu.objects");
    this.calcCategories();
    this.calcOrderGoods();
    //this.$refs.graf1.chartData.labels = this.chart.labels;
    //this.$refs.graf1.chartData.datasets[0].data = this.chart.data;
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
  },
  computed: {
    chartOptions() {
      return {
        title: {
          display: true,
          text: "Расходы на товары",
        },
        legend: {
          display: this.showLegend,
          position: "right",
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
  methods: {
    onDetailTableClick(d) {
      this.$emit("tableClick", d);
    },

    tableSellClick(row, column, cell, event) {
      if (column.property == "name_doc") {
        let d = { row, event, field: { isMenu: true, name: column.property } };
        this.onDetailTableClick(d);
      }
    },
    formatNumber(row, col, value, index) {
      return isNaN(value) ? value : value ? value.toLocaleString("ru-Ru") : "";
    },

    loadChildren(tree, treeNode, resolve) {
      if (tree.level == "l_1" || tree.level == "l_2") {
        let res = [];
        Object.keys(this.expensesCalc[tree.level]).forEach((k) => {
          if (k.includes(tree.children_name)) {
            if (Array.isArray(this.expensesCalc[tree.level][k])) res = [...res, ...this.expensesCalc[tree.level][k]];
            else res.push(this.expensesCalc[tree.level][k]);
          }
        });
        resolve(res);
      }

      resolve([]);
    },
    async calcCategories() {
      let calc = {};
      let resp = null;
      let q = `SELECT og.id, og.object_code,ogt.name,ogt.category_id, ogt.price_real , og.operation_type, IFNULL(cat.name,'без категории') 'cat_name'
FROM accounting_doc_order_goods og
LEFT JOIN accounting_doc_order_goods_table ogt ON og.id=ogt.parent_id
LEFT JOIN accounting_dir_category_services cat ON cat.id=ogt.category_id
WHERE og.object_code=${this.id} AND og.operation_type IN (1,2)  AND og.STATUS=2
`;
      try {
        resp = await this.$axios.post("/accounting/records/report-sql", { q });
        if (resp.data.status !== "ok") {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка сбора данных",
          });
          return;
        }
        let goods = resp.data.data;
        goods.forEach((g) => {
          calc[g.cat_name] = (calc[g.cat_name] || 0) + g.price_real;
        });
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка сбора данных",
        });
        return;
      }
      this.$refs.graf1.chartData.datasets[0].data = [];
      this.$refs.graf1.chartData.labels = [];
      for (let key in calc) {
        let d = this.$refs.graf1.chartData.datasets[0].data || [];
        let l = this.$refs.graf1.chartData.labels || [];
        l = [...l, key];
        d = [...d, calc[key]];
        this.$refs.graf1.chartData.labels = l;
        this.$refs.graf1.chartData.datasets[0].data = d;
      }

      q = `SELECT og.id, og.object_code,ser.name,ogt.category_id, ogt.price , og.operation_type, IFNULL(cat.name,'без категории') 'cat_name'
FROM accounting_doc_order_services og
LEFT JOIN accounting_doc_order_services_table ogt ON og.id=ogt.parent_id
LEFT JOIN accounting_dir_category_services cat ON cat.id=ogt.category_id
LEFT JOIN accounting_dir_services ser ON ser.id=ogt.service_id
WHERE og.type=1 and og.object_code=${this.data.id} AND og.operation_type IN (11)  AND og.STATUS=2
UNION all
SELECT  aw.id, aw.object_id, services.name, cat.id , aw.value, null,   IFNULL(cat.name,'без категории')
FROM  accounting_doc_act_work aw 
LEFT JOIN accounting_dir_services services ON services.id=aw.service_id
LEFT JOIN accounting_dir_category_services cat ON cat.id=services.category_id
WHERE aw.object_id=${this.data.id} AND aw.STATUS=2
`;
      calc = {};
      try {
        resp = await this.$axios.post("/accounting/records/report-sql", { q });
        if (resp.data.status !== "ok") {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка сбора данных",
          });
          return;
        }
        let goods = resp.data.data;
        goods.forEach((g) => {
          calc[g.cat_name] = (calc[g.cat_name] || 0) + g.price;
        });
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка сбора данных",
        });
        return;
      }
      this.$refs.graf_service.chartData.datasets[0].data = [];
      this.$refs.graf_service.chartData.labels = [];
      for (let key in calc) {
        let d = this.$refs.graf_service.chartData.datasets[0].data || [];
        let l = this.$refs.graf_service.chartData.labels || [];
        l = [...l, key];
        d = [...d, calc[key]];
        this.$refs.graf_service.chartData.labels = l;
        this.$refs.graf_service.chartData.datasets[0].data = d;
      }
    },
    async calcOrderGoods() {
      let calc = {};
      let resp = null;

      try {
        resp = await this.$axios.get("/mechti/construction-goods", { params: { filters: { object_id: this.data.id } } });
        if (resp.data.status !== "ok") {
          this.$root.$emit("show-info", {
            type: "error",
            text: "Ошибка сбора данных",
          });
          return;
        }
        let goods = resp.data.data;
        goods.forEach((g) => {
          calc[`g_${g.status}`] = (calc[`g_${g.status}`] || 0) + 1;
          if (g.tasks_status) {
            let s = g.tasks_status;
            if ([201, 280, 205].includes(s)) s = 201;
            else if ([202, 281].includes(s)) s = 202;
            else if ([200, 203, 204, 299].includes(s)) s = s;
            else s = 199;
            calc[s] = (calc[s] || 0) + 1;
          }
        });
      } catch (error) {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка сбора данных",
        });
        return;
      }
      let legend = [
        { label: "Не запущено", color: "#DD1B16" },
        { label: "Запущено", color: "#41B883" },
        { label: "Новая задача", color: "#ad021b" },
        { label: "Ожидает закупа", color: "#c4166d" },
        { label: "Закуплено", color: "#FFEA00" },
        { label: "На складе", color: "#02ad3b" },
        { label: "На объекте", color: "#41B883" },
        { label: "Отменено", color: "#00D8FF" },
        { label: "??", color: "#ccc935" },
      ];
      this.$refs.grafBuying.chartData.labels = legend.map((l) => l.label);
      this.$refs.grafBuying.chartData.datasets = [];
      this.$refs.grafBuying.chartData.datasets.push({
        data: [calc["g_-1"], calc["g_0"], 0, 0, 0, 0, 0, 0, 0],
        backgroundColor: legend.map((l) => l.color),
      });

      this.$refs.grafBuying.chartData.datasets.push({
        label: "Закуп",
        data: [calc["g_-1"], 0, calc["200"], calc["201"], calc["202"], calc["203"], calc["204"], calc["299"], calc["199"]],
        backgroundColor: legend.map((l) => l.color),
      });
    },
  },
};
</script>

<style lang="sass">
.el-table .cell
  word-break: break-word
.showEmptyText
  position: absolute
  width: 100%
  height: 100%

td.active
  background-color: rgba(75, 192, 192, 0.4) !important
.legend-chk
  margin: 0 !important
  padding: 0 !important

.v-slide-group1__prev--disabled
  display: none !important
.v-slide-group1__next--disabled
  display: none !important
</style>
